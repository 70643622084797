* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  body{
    background: #000;
  }
  
  .slider {
    margin:0 20px;
    overflow:"hidden";
    padding:2rem 0;
  }
  
  .slider img {
    width: 100%;
    border-radius:10px;
  }
  
  .react-multi-carousel-list {
  padding: 0rem 0 2rem 0;
  }
  
  .custom-dot-list-style button{
  border: none;
  background: rgb(255, 68, 68);
  }
  .react-multi-carousel-dot.react-multi-carousel-dot--active button{
  background: rgb(255, 68, 68) !important;
  }

  #loadingBox{
    border: 0px solid white;
    background-color: black;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
#loading{
    border: 0px solid white;
    display: block;
    margin: auto;
    width: 30%;
}


@media (max-width:780px){
    #loadingBox{
        border: 0px solid white;
        height: 100vh;
    }
    #loading{
        border: 0px solid white;
        width: 80%;
    }
}

@font-face {
  font-family: 'Aurora';
  src: url('../assets/Aurora.OTF') format('opentype'),
}

@font-face {
  font-family: 'Milk';
  src: url('../assets/Lemonmilk.otf') format('opentype'),
}

