
html {
    scroll-behavior: smooth;
}
*{
    margin: 0;
    padding: 0;
    font-family:Roboto;
}
body{
    overflow-x: hidden;
    background-color: rgb(0, 0, 0);
    
}

*::-webkit-scrollbar{
    width: 7px;
    height: 8px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50px;
}
*::-webkit-scrollbar-thumb{
    background-color: rgba(140, 39, 37,0.7);
    border-radius: 50px;
}

